@import url("http://fonts.cdnfonts.com/css/sf-pro-display");

* {
  margin: 0px;
  padding: 0px;
}

h1 {
  margin-top: 0;
}

.is-mobile-view {
  margin-top: 20px !important;
  margin-bottom: 80px !important;
}

.avatar-initials-image {
  font-family: Inter, sans-serif;
  font-size: 44pt;
  line-height: 100px;

  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-image: linear-gradient(var(--white-color), var(--color));
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
  align-items: center;
}

.group-avatar-image {
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
  align-items: center;
}

.row-container {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.footer-container {
  width: 100%;
  position: fixed;
  display: flexbox;
  bottom: 2px;
}

.groupName {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.03em;
  color: #141416;
  margin-top: 20px;
  margin-bottom: 6px;
  align-items: center;
  justify-content: center;
}

.groupLink {
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-family: Inter, sans-serif;
  text-align: center;
  align-content: center;
  letter-spacing: -0.45px;
  color: #0071ee;
}

.subscribers {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-top: 6px;
  color: #7c7c7c;
}

.groupDescription {
  margin-top: 22px;
  margin-bottom: 26px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #141416;
}

.externalDescription {
}

.externalInfo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.externalInfo i {
  color: #0071ee;
}

.externalInfoContent {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #5b5f64;
  margin-left: 8px;
}

.createdByContainer {
  margin-top: 26px;
}

.createdByGoat {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 510;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #8e8e93;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.createdByUsers {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.createdByUser {
  width: calc(25% - 14px);
  margin: 0 7px;
  margin-bottom: 10px;
}

.createdByGoatName {
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  color: #1c1c1e;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  text-transform: capitalize;
}

.created-by-goat-initials-avatar {
  font-family: Inter, sans-serif;
  font-size: 16pt;
  line-height: 40px;

  height: 0;
  padding-bottom: 100%;
  width: 100%;
  border-radius: 50%;
  background-image: linear-gradient(var(--white-color), var(--color));
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;
}

.created-by-goat-initials-avatar:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  content: attr(data-title);
}

.createdByGoatAvatar {
  object-fit: cover;

  font-family: Inter, sans-serif;

  height: auto;
  width: 100%;
  border-radius: 50%;
  background-image: linear-gradient(var(--white-color), var(--color));
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
  align-items: center;
  cursor: pointer;
}

.benefit-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-around;
  margin: 0 auto;
  text-align: left;
}

.check {
  width: 30px;
  height: 40px;
}

.benefit {
  --benefit-font-size: 16px;

  display: flex;
  font-size: var(--benefit-font-size);
  font-family: Inter, sans-serif;
  text-align: left;
  color: #141416;
}

.joinGroupButton {
  --row-background: #0071ee;
  --row-text-color: white;

  border-radius: 8px;
  text-align: center;
  background: var(--row-background);
  color: var(--row-text-color);
  font-family: Inter, sans-serif;
  font-weight: bold;
  position: relative;
  cursor: pointer;
  padding: 10px 15px;
  /* text-transform: uppercase; */
}

.download-on-my-phone {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #8e8e93;
  margin-top: 16px;
}

.row {
  --row-width: 20%;
  --row-background: #0071ee;
  --row-text-color: white;

  width: var(--row-width);
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  border-radius: 20px;
  font-weight: bold;
  text-align: center;
  background: var(--row-background);
  color: var(--row-text-color);
  font-family: Inter, sans-serif;
  font-size: medium;
  position: relative;
  cursor: pointer;
}

.row-footer {
  width: 80%;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  border-radius: 8px;
  text-align: center;
  background: var(--row-background);
  color: var(--row-text-color);
  font-family: Inter, sans-serif;
  font-size: medium;
  position: relative;
}

.row img {
  position: absolute;
  top: 0px;
  left: 0px;
}

.row-logo {
  margin: 0 auto;
  text-align: center;
  align-items: center;
}

.avatar-space {
  /*margin-top: 5%;*/
  margin-bottom: 1px;
}

.row-space {
  margin-top: 16px;
  margin-bottom: 16px;
}

.logo-space {
  margin-top: 64px;
}

.username {
  --username-text-color: black;

  margin-top: 12px;
  margin-bottom: 8px;
  font-size: 22px;
  font-family: Inter, sans-serif;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-style: bold;
  color: var(--username-text-color);
}

.bio {
  --bio-width: 30%;
  --bio-text-color: black;

  justify-content: left;
  margin-top: 4px;
  margin-bottom: 16px;
  font-size: 16px;
  font-family: Inter, sans-serif;
  align-items: center;
  text-align: center;
  color: var(--bio-text-color);
  margin-left: auto;
  margin-right: auto;
  width: var(--bio-width);
}

.cover {
  object-fit: cover;

  font-family: Inter, sans-serif;

  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-image: linear-gradient(var(--white-color), var(--color));
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
  align-items: center;
}

.group-cover {
  object-fit: cover;

  font-family: Inter, sans-serif;

  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-image: linear-gradient(var(--white-color), var(--color));
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
  align-items: center;
}

.footer-anchor {
  display: flexbox;
  position: fixed;
  bottom: 150px;
  height: 30px;
  width: 30%;
}

.theme-background {
  --top-gradient: white;
  --bottom-gradient: white;

  background: linear-gradient(var(--top-gradient), var(--bottom-gradient));
  height: 100%;
  width: 100%;
  background-image: url("../../assets/images/background.png");
  overflow-y: auto;
  min-height: calc(100vh - 60px);
}

.border-round-object {
  background: #ffffff;
  border-radius: 12px;
  padding: 24px 30px;
  max-width: 450px;
  margin: 60px auto 20px;
  width: calc(100% - 30px);
}

.temp-redirect-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 55px;
  background-color: #141416;
}

.temp-redirect-footer-content {
  padding: 8px 0;
  max-width: 450px;
  width: calc(100% - 30px);
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.temp-redirect-footer-content img {
  height: 39px;
  cursor: pointer;
}

.row-icon {
  float: left;
  padding-top: 10px;
  padding-left: 10px;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

div[data-modal="true"] > div {
  border-radius: 8px;
}

.qr-code-modal-image {
  padding: 20px;
  margin: 0 auto;
  width: 296px;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.modal-header i {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 0;
}

.PrivateGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  padding: 48px 35px;
  border-radius: 24px;
  max-width: 450px;
  margin: 60px auto 20px;
  width: calc(100% - 30px);
  font-family: Inter, sans-serif;
}

.PrivateGroup .avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-bottom: 18px;
}

.PrivateGroup .text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;
  gap: 4px;
}

.PrivateGroup .group-name {
  font-size: 38px;
  line-height: 46px;
  font-weight: 700;
  text-align: center;
}

.PrivateGroup .group-description {
  margin-top: 18px;
  font-size: 16px;
  line-height: 24px;
  max-width: 300px;
  text-align: center;
}

.PrivateGroup .join-group-button {
  display: flex;
  height: 48.665px;
  padding: 9.733px 29.199px;
  justify-content: center;
  align-items: center;
  gap: 4.866px;
  flex: 1 0 0;
  border-radius: 60.831px;
  background: #0071ee;
  color: #ffffff;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-family: Inter, sans-serif;
}
